import { makeStyles } from "@mui/styles"
import color from "color"
import { useTranslations } from "next-intl"
import PropTypes from "prop-types"
import { LoadingIndicator } from "~/components/admin/loading-indicator"

const useStyles = makeStyles(theme => ({
  overlay: {
    alignItems: "center",
    background: color(theme.palette.aoeu.whiteSmoke).fade(0.15).string(),
    bottom: "0",
    display: "flex",
    fontSize: "2rem",
    justifyContent: "center",
    left: "0",
    margin: "-1rem 0",
    position: "fixed",
    right: "0",
    top: "0",
    zIndex: "10002",

    ["& > .svgbox-icon"]: {
      height: "2rem",
      width: "2rem",
    },

    ["& span span"]: {
      minWidth: "1.5rem",
    },
  },
}))

export function LoadingOverlay({ show, label }) {
  const t = useTranslations("components.loadingOverlay")
  const styles = useStyles()

  return (
    <LoadingIndicator
      show={show}
      delayRender={false}
      label={label || t("loaderText")}
      spinnerSize="1.85rem"
      className={styles.overlay}
    />
  )
}

LoadingOverlay.propTypes = {
  show: PropTypes.bool.isRequired,
  label: PropTypes.string,
}

LoadingOverlay.defaultProps = {
  show: false,
  label: "",
}
