import { LinearProgress } from "@mui/material"
import { withStyles } from "@mui/styles"
import clsx from "clsx"
import { useTranslations } from "next-intl"
import Head from "next/head"
import { FC, ReactElement, ReactNode, useEffect } from "react"
import { theme } from "@/styles/admin-theme"
import { styled } from "@/styles/stitches.config"
import { adminUrls } from "@/urls"
import { LoadingOverlay } from "~/components/admin/loading-overlay"
import { Container } from "~/components/container"
import { Header as NewHeader } from "~/components/new-header"
import { SvgIcons } from "~/components/svg-icons"
import { SvgLogos } from "~/components/svg-logos"
import { MaintenanceMode } from "~/containers/maintenance-mode"
import { FEATURES, ROLES } from "~/enums"
import { useAuth } from "~/hooks/use-auth"
import { useRemoveElements } from "~/hooks/use-remove-elements"
import { useLoading, useRouter } from "~/hooks/use-router"
import { isNotEmpty } from "~/util"

export const SCROLLABLE_CONTAINER_CLASS = "FLEX-layout"

const MainContent = styled("main", {
  "position": "relative",
  "width": "100%",
  "&.FLEX-layout": {
    "marginTop": "$space$appBarHeight",
    "height": "calc(100vh - $space$appBarHeight)",
    "overflow": "auto",
    ".loading-container": {
      position: "sticky",
      zIndex: 10,
      top: 0,
      height: "0px",
      width: "100%",
    },
  },
})

const resolvePageTitle = (t: Translator, page?: string, title?: string) => {
  const mainTitle = t("mainTitle")

  if (page === "home") {
    return `${t("mainTitle")} | ${t("homePageSubTitle")}`
  }

  if (isNotEmpty(title)) {
    return `${title} - ${mainTitle}`
  }

  return mainTitle
}

type LayoutProps = {
  page?: string
  title?: string
  mainBackgroundColor?: string
  children?: ReactNode
  className?: string
}

export function Layout({
  page,
  title,
  mainBackgroundColor = "$whiteSmoke",
  children,
  className,
}: LayoutProps): ReactElement {
  const t = useTranslations("containers.layout")
  const { features } = useAuth()
  const maintenanceMode = features.has(FEATURES.MAINTENANCE_MODE)

  const router = useRouter()
  const [isLoading] = useLoading(router)

  return maintenanceMode ? (
    <MaintenanceMode />
  ) : (
    <NewHeader>
      <Head>
        <title>{resolvePageTitle(t, page, title)}</title>
      </Head>
      <SvgLogos />
      <SvgIcons />
      <MainContent
        css={{ backgroundColor: mainBackgroundColor }}
        className={clsx(className, SCROLLABLE_CONTAINER_CLASS)}>
        <div className="loading-container">
          {isLoading && <LinearProgress variant="query" />}
        </div>
        {children}
      </MainContent>
    </NewHeader>
  )
}

// https://material-ui.com/customization/components/#global-css-override
const GlobalStyles = withStyles(() => {
  return {
    ["@global"]: {
      a: {
        borderBottom: `1px solid ${theme.palette.aoeu.aoeuBlue}`,
        color: theme.palette.aoeu.darkBlue,
        textDecoration: "none",
      },
    },
  }
})(() => null)

type SharedAdminLayoutProps = {
  canAccess: boolean
  windowTitle: string
  className?: string
  children?: ReactNode
}

/**
 * Shared core layout between Admin and Organization layouts
 */
const SharedAdminLayout: FC<SharedAdminLayoutProps> = props => {
  const lt = useTranslations("components.loadingOverlay")
  const router = useRouter()
  const [isLoading] = useLoading(router)

  /* we don't want the chat bubble or the feature advertisement modal shown in the
   * admin portal, as these are not helpful for admin users.
   * */
  useRemoveElements(".leadinModal", "#hubspot-messages-iframe-container")

  return (
    <NewHeader>
      <Head>
        <title>{props.windowTitle}</title>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <GlobalStyles />
      </Head>
      <SvgLogos />
      <SvgIcons />
      <LoadingOverlay
        show={!props.canAccess}
        label={lt("loaderPermissionsText")}
      />
      {props.canAccess && (
        <MainContent className={clsx("FLEX-layout", props.className)}>
          <div className="loading-container">
            {isLoading && <LinearProgress variant="query" />}
          </div>
          <Container css={{ padding: "$4" }}>{props.children}</Container>
        </MainContent>
      )}
    </NewHeader>
  )
}

export type AdminLayoutProps = {
  pageTitle?: string
  windowTitle?: string
  children?: ReactNode
  className?: string
}

export function AdminLayout({
  pageTitle,
  windowTitle,
  children,
  className,
}: AdminLayoutProps): ReactElement {
  const t = useTranslations("containers.layout.admin")
  const resolvedWindowTitle = `${windowTitle || t("defaultWindowTitle")} | ${
    pageTitle || t("windowSubTitle")
  }`
  const {
    user: { roles = [] },
  } = useAuth()
  const canAccessAdminPortal =
    roles?.includes(ROLES.ADMIN_PORTAL_ACCESS) ?? false

  usePermissionGuard(canAccessAdminPortal)

  return (
    <SharedAdminLayout
      canAccess={canAccessAdminPortal}
      windowTitle={resolvedWindowTitle}
      className={className}>
      {children}
    </SharedAdminLayout>
  )
}

export type AdminOrganizationLayoutProps = {
  pageTitle?: string
  windowTitle?: string
  children?: ReactNode
  className?: string
}

/**
 * Layout for admin users who have access to the organization management pages (e.g. district admins or AOEU admins)
 * If the user does not have access to the organization management pages, they will be redirected to the login page
 */
export const AdminOrganizationLayout: FC<
  AdminOrganizationLayoutProps
> = props => {
  const t = useTranslations("containers.layout.admin")
  const resolvedWindowTitle = `${
    props.windowTitle || t("defaultWindowTitle")
  } | ${props.pageTitle || t("windowSubTitle")}`
  const {
    user: { roles = [] },
    isDistrictAdmin,
  } = useAuth()

  const canAccessOrganizationManagement =
    (roles?.includes(ROLES.ADMIN_PORTAL_ACCESS) || isDistrictAdmin) ?? false

  usePermissionGuard(canAccessOrganizationManagement)

  return (
    <SharedAdminLayout
      canAccess={canAccessOrganizationManagement}
      windowTitle={resolvedWindowTitle}
      className={props.className}>
      {props.children}
    </SharedAdminLayout>
  )
}

/**
 * Redirects to login page if user does not have access to the page
 */
function usePermissionGuard(canAccess: boolean) {
  const router = useRouter()

  useEffect(() => {
    if (!canAccess) {
      router.push({
        pathname: adminUrls.login,
        query: {
          returnTo: router.asPath,
        },
      })
    }
  }, [canAccess, router])
}
