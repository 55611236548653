import { useEffect, useRef, useState } from "react"
import { withDocument } from "~/util"

export const useRemoveElements = (...querySelectors: string[]): void => {
  const mutationObserver = useRef<MutationObserver>()
  const [selectors, setSelectors] = useState(querySelectors)

  useEffect(() => {
    withDocument(document => {
      mutationObserver.current = new MutationObserver(mutations => {
        let shouldUpdateState = false
        // function to compute the selector index to remove from the list of remaining selectors
        const toRemove = (
          (length: number) => (idx: number) =>
            length - 1 - idx
        )(selectors.length)
        selectors
          .slice()
          .reverse()
          .forEach((sel, idx) => {
            const el = document.querySelector(sel)
            if (document.contains(el)) {
              shouldUpdateState = true
              el?.remove()
              selectors.splice(toRemove(idx), 1)
            }
          })
        // if any of the selectors have been removed from the DOM, we update the
        // internal state of the hook to stop looking for them
        if (shouldUpdateState) {
          setSelectors(() => selectors)
        }
        // once there are no selectors left, we stop observing
        if (!selectors.length) mutationObserver.current?.disconnect()
      })
      mutationObserver.current.observe(document, {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: true,
      })
    })
    return () => {
      mutationObserver.current && mutationObserver.current.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
