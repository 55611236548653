import Spinner from "@mui/material/CircularProgress"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import { useTranslations } from "next-intl"
import PropTypes from "prop-types"
import { useEffect, useState, useRef } from "react"
import { useInterval } from "react-use"
import { TIME } from "~/enums"

const useStyles = makeStyles({
  loadingIndicator: {
    alignItems: "center",
    cursor: "default",
    display: "flex",
    height: "auto",
    margin: "1rem 0",
    opacity: "1",
    transition: "all 0.5s",
    userSelect: "none",
    visibility: "visible",
  },

  loadingIndicatorHidden: {
    height: "0",
    opacity: "0",
    visibility: "hidden",
  },

  spinner: {
    marginRight: "0.75rem",
  },

  dots: {
    display: "inline-block",
    marginLeft: "0.15rem",
    minWidth: "1rem",
    transition: "opacity 0.5s ease-in-out, visibility 0.5s ease-in-out",
  },

  dotsVisible: {
    opacity: "1",
    visibility: "visible",
  },

  dotsHidden: {
    opacity: "0",
    visibility: "hidden",
  },
})

export function LoadingIndicator({
  show,
  showLabel,
  label,
  spinnerSize,
  className,
  delayRender,
  delayTime,
  ...attributes
}) {
  const MAX_DOTS = 5
  const mounted = useRef(false)
  const [delayElapsed, setDelayElapsed] = useState(false)
  const [dotCount, setDotCount] = useState(0)
  const t = useTranslations("components.loadingIndicator")
  const defaultLabel = t("defaultLabel")
  const classes = useStyles()

  useEffect(() => {
    mounted.current = true

    return () => (mounted.current = false)
  })

  const delayRenderTimer = useRef(null)
  useEffect(() => {
    if (mounted.current && delayRender) {
      delayRenderTimer.current = setTimeout(
        () => setDelayElapsed(true),
        delayTime,
      )
    }

    return () => {
      if (delayRenderTimer.current) {
        clearTimeout(delayRenderTimer.current)
      }
    }
  })

  /* eslint-disable react/display-name */
  const dotHash = {
    0: () => (
      <span className={classes.dots}>
        <span className={classes.dotsVisible}>.</span>
        <span className={classes.dotsHidden}>..</span>
      </span>
    ),
    1: () => (
      <span className={classes.dots}>
        <span className={classes.dotsVisible}>..</span>
        <span className={classes.dotsHidden}>.</span>
      </span>
    ),
    2: () => (
      <span className={clsx([classes.dots, classes.dotsVisible])}>...</span>
    ),
    3: () => (
      <span className={classes.dots}>
        <span className={classes.dotsHidden}>.</span>
        <span className={classes.dotsVisible}>..</span>
      </span>
    ),
    4: () => (
      <span className={classes.dots}>
        <span className={classes.dotsHidden}>..</span>
        <span className={classes.dotsVisible}>.</span>
      </span>
    ),
    5: () => <span className={clsx([classes.dots, classes.dotsHidden])} />,
  }
  /* eslint-enable react/display-name */

  const renderDots = () => {
    const dots = dotHash[dotCount]

    return dots()
  }

  const incrementDotCount = () => {
    if (mounted.current) {
      const reset = dotCount === MAX_DOTS

      setDotCount(reset ? 0 : dotCount + 1)
    }
  }

  useInterval(incrementDotCount, 350)

  const showIt = show && (!delayRender || (delayRender && delayElapsed))

  return showIt ? (
    <div
      data-testid="loading-indicator"
      className={clsx([
        classes.loadingIndicator,
        className,
        {
          [classes.loadingIndicator.hidden]: !show,
        },
      ])}
      {...attributes}>
      <Spinner size={spinnerSize} className={classes.spinner} />
      {showLabel && (
        <span className="loading-indicator--label">
          {label || defaultLabel}
          {renderDots()}
        </span>
      )}
    </div>
  ) : null
}

LoadingIndicator.propTypes = {
  show: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  spinnerSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  delayRender: PropTypes.bool,
  delayTime: PropTypes.number,
}

LoadingIndicator.defaultProps = {
  show: true,
  showLabel: true,
  spinnerSize: "1.25rem",
  delayRender: true,
  delayTime: TIME.ONE_SECOND,
}
