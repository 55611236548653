import UserIcon from "@mui/icons-material/AccountCircle"
import { makeStyles } from "@mui/styles"
import BoringAvatar from "boring-avatars"
import { ReactElement } from "react"
import { aoeuPalette } from "@/styles/admin-theme"
import { getInitials } from "admin/utils"

const useStyles = makeStyles(theme => ({
  avatar: {
    alignItems: "center",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
  },
  boringAvatar: {
    position: "absolute",
    zIndex: 0,
  },
  initials: {
    color: theme.palette.aoeu.whiteSmoke,
    fill: theme.palette.aoeu.whiteSmoke,
    fontSize: "15rem",
    fontWeight: 500,
    textShadow: "0.0625rem 0.0625rem 0.125rem #000",
    zIndex: 1,
    position: "absolute",
  },
  userIcon: {
    color: "rgba(0, 0, 0, 0.35)",
    zIndex: 1,
  },
}))

const colors = [
  aoeuPalette.aoeuBlue,
  aoeuPalette.darkBlue,
  aoeuPalette.appleGreen,
  aoeuPalette.vividCerise,
  aoeuPalette.mangoTango,
  aoeuPalette.sunglow,
  aoeuPalette.oracle,
]

export type AvatarProps = {
  name: string
  initials?: string
  size?: number | string
  square?: boolean
  title?: boolean
  variant?: "marble" | "beam" | "pixel" | "sunset" | "ring" | "bauhaus"
}

export function Avatar({
  name,
  initials = getInitials(name ?? ""),
  size = 28,
  ...attributes
}: AvatarProps): ReactElement {
  const styles = useStyles()
  const avatarStyle = {
    height: `${size}px`,
    width: `${size}px`,
  }

  return initials ? (
    <span className={styles.avatar} style={avatarStyle}>
      <BoringAvatar
        size={size}
        name={name}
        variant="marble"
        colors={colors}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className={styles.boringAvatar}
        {...attributes}
      />
      <svg
        className={styles.initials}
        height={size}
        width={size}
        viewBox="0 0 500 75"
        preserveAspectRatio="xMinYMid meet"
        xmlns="http://www.w3.org/2000/svg">
        <text x="50%" y="75%" dominantBaseline="middle" textAnchor="middle">
          {initials}
        </text>
      </svg>
    </span>
  ) : (
    <UserIcon className={styles.userIcon} sx={{ width: size, height: size }} />
  )
}
