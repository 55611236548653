import { useState, useEffect } from "react"

export function useIsBrowser(): boolean {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  return isBrowser
}
