import { useTranslations } from "next-intl"
import Head from "next/head"
import { styled } from "@/styles/stitches.config"
import { pages } from "@/urls"
import { NextWrappedLink } from "~/components/link"
import { Logo, SvgLogos } from "~/components/svg-logos"

export const MaintenanceMode = styled(
  function ({ className }: { className?: string }) {
    const t = useTranslations("containers.maintenanceMode")

    return (
      <div className={className}>
        <Head>
          <title>{t("pageTitle")}</title>
        </Head>
        <header>
          <SvgLogos />
          <NextWrappedLink href={pages.home} decorated={false} outlined={false}>
            <Logo
              symbolId="aoeu-full-logo"
              className="maintenance-mode--header--logo"
            />
          </NextWrappedLink>
        </header>
        <main>
          <h1>{t("pageHeading")}</h1>
          <p>{t("pageMessage")}</p>
          <img
            height="540"
            width="540"
            src="/maintenance-mode.png"
            alt="maintenance mode"
          />
        </main>
        <footer>
          <p>&copy; {t("copyrightNotice")}</p>
        </footer>
      </div>
    )
  },
  {
    "$$logoHeight": "3.8125rem",

    "header": {
      background: "$aoeuBlue",
      display: "flex",
      justifyContent: "center",
    },
    ".maintenance-mode--header--logo": {
      color: "$white",
      height: "$$logoHeight",
      margin: "2rem",
    },
    "main": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      fontSize: "1.25rem",
      justifyContent: "center",
      margin: "2rem",
      p: { fontSize: "1.5rem", fontWeight: 400 },
    },
    "footer": {
      background: "oracle",
      display: "flex",
      justifyContent: "center",
      p: { color: "#9eb1b5", fontSize: "1.05rem", margin: "2rem" },
    },
  },
)
