/* eslint-disable @typescript-eslint/ban-ts-comment */
import NextLink from "next/link"
import React, {
  forwardRef,
  LegacyRef,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from "react"
import { styled } from "@/styles/stitches.config"
import { trackClick, TrackingProps } from "~/components/button"
import { useAuth } from "~/hooks/use-auth"
import { useResource } from "~/hooks/use-resource"
import { TRACKING_EVENT_TYPES } from "~/services/aoeu/models"

export type LinkProps = PropsOf<typeof Link>
export const Link = styled("a", {
  variants: {
    decorated: {
      true: {
        "color": "$darkBlue",
        "textDecoration": "none",
        "transition": "all 250ms ease-in-out",
        "outline": 0,
        "&:focus": {
          borderRadius: "0.1875rem",
          boxShadow: "0 0 0 0.0625rem $allPorts",
        },
        "&:hover": {
          color: "$aoeuBlue",
        },
      },
      false: {
        color: "inherit",
        textDecoration: "inherit",
      },
      unset: {},
    },
    outlined: {
      false: {
        outline: "none",
      },
    },
  },
  defaultVariants: {
    decorated: "true",
    outlined: "true",
  },
})

export const LinkButton = styled(
  ({
    onClick,
    eventTitle,
    eventType = TRACKING_EVENT_TYPES.CLICK,
    ...props
  }: Omit<LinkProps, "href"> & TrackingProps): ReactElement => {
    const { trackingApi } = useResource()
    const auth = useAuth()
    const _onClick: typeof onClick = (...args) => {
      trackClick(trackingApi, auth, eventTitle, eventType)
      return onClick && onClick(...args)
    }
    return <Link onClick={_onClick} {...props} role="button" />
  },
  {
    "&:hover": {
      cursor: "pointer",
    },
  },
)

export const NextWrappedLink = forwardRef(
  (
    props: {
      children?: ReactNode
      href?: string
      className?: string
      onClick?: MouseEventHandler<HTMLAnchorElement>
      eventTitle?: string | null
      eventType?: TrackingProps["eventType"]
    } & LinkProps,
    _ref: LegacyRef<HTMLAnchorElement>,
  ) => {
    const {
      children,
      href,
      className,
      onClick,
      eventTitle,
      eventType,
      ...otherProps
    } = props
    const { trackingApi } = useResource()
    const auth = useAuth()

    const _onClick: typeof onClick = (...args) => {
      if (eventType && eventTitle) {
        trackClick(trackingApi, auth, eventTitle, eventType)
      }
      return onClick && onClick(...args)
    }

    return href ? (
      <Link
        as={NextLink}
        href={href}
        className={className}
        onClick={_onClick}
        {...otherProps}
        // @ts-ignore
        ref={_ref}>
        {children}
      </Link>
    ) : (
      <Link
        className={className}
        onClick={onClick}
        {...otherProps}
        // @ts-ignore
        ref={_ref}>
        {children}
      </Link>
    )
  },
)

NextWrappedLink.displayName = "NextWrappedLink"

export function generateDownloadHref(
  title: string,
  href: string,
): Optional<string> {
  if (!title || !href) return undefined
  // split on "." and get the very last item
  const ext = href.split(".").slice(-1)[0]
  // if we were unable to split on ".", the href is likely malformed, and we should not attempt
  // to format it.
  if (ext === href) return undefined
  // Sanity CDN supports the ?dl query param which sets the content disposition for the
  // link to download. https://www.sanity.io/docs/assets#f489d879488c
  return `${href}?dl=${encodeURIComponent(title)}.${ext}`
}

export default Link
